<template>
  <div class="lazy-scroll">
    <div class="wrapper-terms">
      <h1 class="center-text uppercase">{{lang[this.appLang].devices_p1}}<br></h1>

      <h2>{{lang[this.appLang].devices_p2}}</h2>

      <h6>{{lang[this.appLang].devices_p3}}</h6>
      <p class="center-text">
        <b>Apple (iPhone)</b>
        <br>Iphone 14 Pro Max
        <br>Iphone 14 Pro
        <br>Iphone 14
        <br>iPhone 13 Pro Max
        <br>iPhone 13 Pro
        <br>iPhone 13 Mini
        <br>iPhone 13
        <br>iPhone 12 Pro Max
        <br>iPhone 12 Pro
        <br>iPhone 12 Mini
        <br>iPhone 12
        <br>iPhone 11 Pro Max
        <br>iPhone 11 Pro
        <br>iPhone 11
        <br>iPhone XS Max
        <br>iPhone XS
        <br>iPhone XR
        <br>iPhone X
        <br>iPhone SE (2020)
        <br><br>{{lang[this.appLang].devices_p4}}
        <br>iPhone 8
        <br>iPhone 7
        <br><b>All future iPhones</b>
        <br>
        <br><b>Samsung Galaxy S</b>
        <br>Galaxy S22, S22+, S22 Ultra
        <br>Galaxy S20, S20+, S20 Ultra
        <br>Galaxy S10, S10+, S10e
        <br>Galaxy S9, S9+
        <br>Galaxy S8, S8+
        <br>Galaxy S7, S7 Edge
        <br>Galaxy S6, S6 Edge
        <br>Galaxy S5, S5 Mini, S5 Neo
        <br>Galaxy S3, S4
        <br>
        <br><b>Samsung Galaxy Note</b>
        <br>Note 10, Note 10+
        <br>Note 9
        <br>Note 8
        <br>
        <br><b>HTC</b>
        <br>U19e, U12+, U12 Life
        <br>Desire 12, Desire 12s
        <br>Desire 10 Pro
        <br>U11, Life, Eyes, +,
        <br>One M9
        <br>Exodus 1
        <br>
        <br><b>Google</b>
        <br>Pixel 4
        <br>Pixel 4 XL
        <br>Pixel 3
        <br>Pixel 3 XL
        <br>Pixel 3a
        <br>Pixel 3a XL
        <br>Pixel 2
        <br>Pixel 2XL
        <br>Pixel
        <br>Pixel XL
        <br>Nexus 5X
        <br>Nexus 6P
        <br>Nexus 6
        <br>
        <br><b>Huawei</b>
        <br>P30, P30 Pro, P30 Lite
        <br>P20, P20 Pro, P20 Lite
        <br>P10, P10 Plus, P10 Lite
        <br>
        <br><b>OnePlus</b>
        <br>7, 7 Pro
        <br>6, 6T
        <br>5, 5T
        <br>3, 3T
        <br>One
        <br>
        <br><b>Samsung Galaxy A</b>
        <br>2019 models: A20e, A40, A50, A70
        <br>2018 models: A6, A7, A8, A9
        <br>2017 models: A3, A5
        <br>2016 models: A3, A5
        <br>
        <br><b>Samsung Galaxy J</b>
        <br>2018 models: J4+, J6, J5, J3
        <br>
        <br><b>LG</b>
        <br>Q9
        <br>One
        <br>G8, G8s ThinQ
        <br>G7, G7 ThinQ
        <br>G3, G4, G5, G6
        <br>V50, V40
        <br>V30, V35
        <br>Q Stylus, Q Stylo 4
        <br>
        <br><b>Xiaomi</b>
        <br>Mi Mix
        <br>Mi Mix2
        <br>Mi Mix 2S
        <br>Mi Mix 3
        <br>Mi5, Mi5s, Mi5 Plus
        <br>Mi6/X, Mi6
        <br>Mi8, Mi8 Lite, Mi8 Pro
        <br>Mi9, Mi9 SE
        <br>
        <br><b>Nokia</b>
        <br>3, 5, 5.1, 6, 6.1
        <br>7 Plus
        <br>8, 8.1, 8 Sirocco
        <br>9 PureView
        <br>
        <br><b>Motorola</b>
        <br>Moto P50
        <br>Moto X4
        <br>Moto Z3
        <br>Moto Z3 Play
        <br>
        <br><b>Sony</b>
        <br>Xperia XZ1/Compact
        <br>Xperia 1, 10/Plus
        <br>Xperia XA1/Ultra/Plus
        <br>Xperia XZ2/Compact/Premium
        <br>Xperia XA2/Ultra/Plus
        <br>Xperia XZ3

      </p>
      <p class="tapni-p margin-bottom-100"></p>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  name: 'Devices',
  methods: {
    ...mapActions(['eventLog'])
  },
  async mounted () {
    this.eventLog({
      type: 'devices_page'
    })
    this.$store.commit('setInitialize', true)
    this.$store.commit('setLoading', false)
  },
}
</script>

<style>
</style>
